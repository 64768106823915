// name: indes.jsx 
// component-name TitleSection
// description: Title in diferents sections for app
// dependencies: React, react-bootstrap, fortawesome
// create-date: 12/09/2021 for Osmaro Bonilla
// last update: 12/09/2021 for Osmaro Bonilla

// import dependencies
import React,{useState, useEffect} from "react"
import Seo from "../components/seo"
import TitleSection from '../components/ModuleGeneral/TitleSection'
import {Container, Row,Col,Card} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCog, faLink,faHashtag, faUser, faTags, faShoppingCart, faIdCardAlt} from '@fortawesome/free-solid-svg-icons'
import {store} from '../components/Firebase/firebase-config'
import Access from '../components/Acess'
import {navigate} from 'gatsby'
import NavBusinessCard from "../components/BusinessCard/NavBusinessCard"
import toastMsg from "../components/Toast"
import { ToastContainer } from 'react-toastify';


const Menu = () => {

    const [urlLink, setUrlLink] = useState('');
    const [urlProduct, setUrlProduct] = useState('');
    const [urlConfig, setUrlConfig] = useState('');
    const [urlCat, setUrlCat] = useState('');
    const [urlTags, setUrlTags] = useState('');
    const [urlUser, setUrlUser] = useState('');
    const [businessCard, setBusinessCard] = useState('');

    const userRoles = async ()=>{
        await store.collection('roles').doc(localStorage.getItem('user')).get()
        .then(res=>{
            localStorage.setItem('roles', JSON.stringify(res.data()));
            if(res.data().businessCard){
                setBusinessCard('/tarjeta-de-presentacion');
            }
            
        });
    };

    const redirected = (url)=>{
        if(url!=''){
            navigate(url);
        }else{
            toastMsg.infoToast("Muy pronto estas secciones estaran disponibles para ti.");
        }
    }

    useEffect(() => {
        if(localStorage.getItem('user') == undefined){
            navigate('/login');
        }else{
            userRoles();
        }
    }, [])
    
    return(
        <>
            <Seo title="Menú" />
            <Access section="businessCard"/>
            <NavBusinessCard />
            <ToastContainer />
            <TitleSection icon="bars" title="Menú"/>
            <Container>
                <Row>
                    <Col sm={12} md={6} lg={4} className="mt-4 mb-4">
                        <button className="btn" style={{textDecoration: "none"}} onClick={()=>{
                            redirected(businessCard);
                        }}>
                            <Card className="shadow" style={{"height": "150px", "borderRadius": "20px"}}>
                                <Card.Body>
                                    <Row style={{
                                            "position": "relative",
                                            "top": "20%"
                                        }}>
                                        <Col xs={4} sm={4} md={4} lg={4}>
                                            <div  className="align-text-middle" style={
                                                {"backgroundColor": "#F4F4F4", 
                                                "borderRadius": "50px", 
                                                "width": "70px", 
                                                "height": "70px", 
                                                "border":"2px solid #9C9C9C"}}>
                                                <FontAwesomeIcon icon={faIdCardAlt} style={
                                                    {
                                                        "fontSize": "40px", 
                                                        "position": "relative", 
                                                        "top": "18%", 
                                                        "left": "0%",
                                                        "color": "#8D8484"
                                                    }
                                                }/>
                                            </div > 
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8}>
                                            <span style={{
                                                "fontSize": "25px",
                                                "fontWeight": "bold",
                                                "position": "relative", 
                                                "bottom": "5%", 
                                                "right": "20%",
                                                "color": "#8D8484"
                                            }}>
                                                <p>Tarjeta de presentación</p>
                                            </span>
                                        </Col>
                                    </Row> 
                                </Card.Body>
                            </Card>
                        </button>
                    </Col>
                    
                    <Col sm={12} md={6} lg={4} className="mt-4 mb-4">
                        <button className="btn" style={{textDecoration: "none", "width": "100%"}} onClick={()=>{
                            redirected(urlLink);
                        }}>
                            <Card className="shadow" style={{"height": "150px", "borderRadius": "20px"}}>
                                <Card.Body>
                                    <Row style={{
                                            "position": "relative",
                                            "top": "20%"
                                        }}>
                                        <Col xs={4} sm={4} md={4} lg={4}>
                                            <div  className="align-text-middle" style={
                                                {"backgroundColor": "#F4F4F4", 
                                                "borderRadius": "50px", 
                                                "width": "70px", 
                                                "height": "70px", 
                                                "border":"2px solid #9C9C9C"}}>
                                                <FontAwesomeIcon icon={faLink} style={
                                                    {
                                                        "fontSize": "40px", 
                                                        "position": "relative", 
                                                        "top": "20%", 
                                                        "left": "0%",
                                                        "color": "#8D8484"
                                                    }
                                                }/>
                                            </div > 
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8}>
                                            <span style={{
                                                "fontSize": "25px",
                                                "fontWeight": "bold",
                                                "position": "relative", 
                                                "top": "15%", 
                                                "right": "40%",
                                                "color": "#8D8484"
                                            }}>
                                                <p>Links</p>
                                            </span>
                                        </Col>
                                    </Row> 
                                </Card.Body>
                            </Card>
                        </button>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="mt-4 mb-4">
                        <button className="btn" style={{textDecoration: "none", "width": "100%"}} onClick={()=>{
                            redirected(urlProduct);
                        }}>
                            <Card className="shadow" style={{"height": "150px", "borderRadius": "20px"}}>
                                <Card.Body>
                                    <Row style={{
                                            "position": "relative",
                                            "top": "20%"
                                        }}>
                                        <Col xs={4} sm={4} md={4} lg={4}>
                                            <div  className="align-text-middle" style={
                                                {"backgroundColor": "#F4F4F4", 
                                                "borderRadius": "50px", 
                                                "width": "70px", 
                                                "height": "70px", 
                                                "border":"2px solid #9C9C9C"}}>
                                                <FontAwesomeIcon icon={faShoppingCart} style={
                                                    {
                                                        "fontSize": "40px", 
                                                        "position": "relative", 
                                                        "top": "20%", 
                                                        "left": "0%",
                                                        "color": "#8D8484"
                                                    }
                                                }/>
                                            </div > 
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8}>
                                            <span style={{
                                                "fontSize": "25px",
                                                "fontWeight": "bold",
                                                "position": "relative", 
                                                "top": "15%", 
                                                "right": "25%",
                                                "color": "#8D8484"
                                            }}>
                                                <p>Productos</p>
                                            </span>
                                        </Col>
                                    </Row> 
                                </Card.Body>
                            </Card>
                        </button>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="mt-4 mb-4">
                        <button className="btn" style={{textDecoration: "none", "width": "100%"}} onClick={()=>{
                            redirected(urlConfig);
                        }}>
                            <Card className="shadow" style={{"height": "150px", "borderRadius": "20px"}}>
                                <Card.Body>
                                    <Row style={{
                                            "position": "relative",
                                            "top": "20%"
                                        }}>
                                        <Col xs={4} sm={4} md={4} lg={4}>
                                            <div  className="align-text-middle" style={
                                                {"backgroundColor": "#F4F4F4", 
                                                "borderRadius": "50px", 
                                                "width": "70px", 
                                                "height": "70px", 
                                                "border":"2px solid #9C9C9C"}}>
                                                <FontAwesomeIcon icon={faCog} style={
                                                    {
                                                        "fontSize": "40px", 
                                                        "position": "relative", 
                                                        "top": "20%", 
                                                        "left": "0%",
                                                        "color": "#8D8484"
                                                    }
                                                }/>
                                            </div > 
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8}>
                                            <span style={{
                                                "width": "100%",
                                                "fontSize": "23px",
                                                "fontWeight": "bold",
                                                "position": "relative", 
                                                "top": "20%", 
                                                "right": "10%",
                                                "color": "#8D8484"
                                            }}>
                                                <p>Configuraciones</p>
                                            </span>
                                        </Col>
                                    </Row> 
                                </Card.Body>
                            </Card>
                        </button>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="mt-4 mb-4">
                        <button className="btn" style={{textDecoration: "none", "width": "100%"}} onClick={()=>{
                            redirected(urlCat);
                        }}>
                            <Card className="shadow" style={{"height": "150px", "borderRadius": "20px"}}>
                                <Card.Body>
                                    <Row style={{
                                            "position": "relative",
                                            "top": "20%"
                                        }}>
                                        <Col xs={4} sm={4} md={4} lg={4}>
                                            <div  className="align-text-middle" style={
                                                {"backgroundColor": "#F4F4F4", 
                                                "borderRadius": "50px", 
                                                "width": "70px", 
                                                "height": "70px", 
                                                "border":"2px solid #9C9C9C"}}>
                                                <FontAwesomeIcon icon={faTags} style={
                                                    {
                                                        "fontSize": "40px", 
                                                        "position": "relative", 
                                                        "top": "20%", 
                                                        "left": "5%",
                                                        "color": "#8D8484"
                                                    }
                                                }/>
                                            </div > 
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8}>
                                            <span style={{
                                                "fontSize": "25px",
                                                "fontWeight": "bold",
                                                "position": "relative", 
                                                "top": "15%", 
                                                "right": "25%",
                                                "color": "#8D8484"
                                            }}>
                                                <p>Categorias</p>
                                            </span>
                                        </Col>
                                    </Row> 
                                </Card.Body>
                            </Card>
                        </button>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="mt-4 mb-4">
                        <button className="btn" style={{textDecoration: "none", "width": "100%"}} onClick={()=>{
                            redirected(urlTags);
                        }}>
                            <Card className="shadow" style={{"height": "150px", "borderRadius": "20px"}}>
                                <Card.Body>
                                    <Row style={{
                                            "position": "relative",
                                            "top": "20%"
                                        }}>
                                        <Col xs={4} sm={4} md={4} lg={4}>
                                            <div  className="align-text-middle" style={
                                                {"backgroundColor": "#F4F4F4", 
                                                "borderRadius": "50px", 
                                                "width": "70px", 
                                                "height": "70px", 
                                                "border":"2px solid #9C9C9C"}}>
                                                <FontAwesomeIcon icon={faHashtag} style={
                                                    {
                                                        "fontSize": "40px", 
                                                        "position": "relative", 
                                                        "top": "20%", 
                                                        "left": "0%",
                                                        "color": "#8D8484"
                                                    }
                                                }/>
                                            </div > 
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8}>
                                            <span style={{
                                                "fontSize": "25px",
                                                "fontWeight": "bold",
                                                "position": "relative", 
                                                "top": "15%", 
                                                "right": "45%",
                                                "color": "#8D8484"
                                            }}>
                                                <p>Tags</p>
                                            </span>
                                        </Col>
                                    </Row> 
                                </Card.Body>
                            </Card>
                        </button>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="mt-4 mb-4">
                        <button className="btn" style={{textDecoration: "none", "width": "100%"}} onClick={()=>{
                            redirected(urlUser);
                        }}>
                            <Card className="shadow" style={{"height": "150px", "borderRadius": "20px"}}>
                                <Card.Body>
                                    <Row style={{
                                            "position": "relative",
                                            "top": "20%"
                                        }}>
                                        <Col xs={4} sm={4} md={4} lg={4}>
                                            <div  className="align-text-middle" style={
                                                {"backgroundColor": "#F4F4F4", 
                                                "borderRadius": "50px", 
                                                "width": "70px", 
                                                "height": "70px", 
                                                "border":"2px solid #9C9C9C"}}>
                                                <FontAwesomeIcon icon={faUser} style={
                                                    {
                                                        "fontSize": "40px", 
                                                        "position": "relative", 
                                                        "top": "20%", 
                                                        "left": "0%",
                                                        "color": "#8D8484"
                                                    }
                                                }/>
                                            </div > 
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8}>
                                            <span style={{
                                                "fontSize": "25px",
                                                "fontWeight": "bold",
                                                "position": "relative", 
                                                "top": "15%", 
                                                "right": "35%",
                                                "color": "#8D8484"
                                            }}>
                                                <p>Usuarios</p>
                                            </span>
                                        </Col>
                                    </Row> 
                                </Card.Body>
                            </Card>
                        </button>
                    </Col>
                </Row>
            </Container>
            <br /><br /><br />
        </>
    );
}

export default Menu
