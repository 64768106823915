// name: indes.jsx 
// component-name TitleSection
// description: Title in diferents sections for app
// dependencies: React, react-bootstrap, fortawesome
// create-date: 12/09/2021 for Osmaro Bonilla
// last update: 12/09/2021 for Osmaro Bonilla

// import dependencies
import React from 'react'
import {Row, Col, Card, Container} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

// construct components
const TitleSection = (params) => {
    // global virables
    const icon = params.icon;
    const title = params.title;
    // Objects for font awesome icons
    const iconList = Object.keys(Icons)
        .filter((key) => key !== 'fas' && key !== 'prefix')
        .map((icon) => Icons[icon]
    );
    // add array object iconlist in library
    library.add(...iconList); 

    // return component and gatsby render
    return (
        <div>
            <Container fluid>
                <Row style={
                {
                    "backgroundColor": "#6389FF", 
                    "borderBottomLeftRadius": "30px", 
                    "borderBottomRightRadius": "30px"
                }
                }>
                <Col md={3}>
                    <Card className=" mt-3 mb-3 shadow" style={{"borderRadius": "15px"}}>
                        <Card.Body>
                        <Card.Title style={{"color": "#5C5C5C"}}>
                            <FontAwesomeIcon icon={icon} style={
                            {
                                "fontSize": "30px"
                            }
                            } /> {` ${title}`}
                        </Card.Title>
                        
                        </Card.Body>
                    </Card>
                </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TitleSection
