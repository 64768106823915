// import dependences
import toastMsg from '../../../Toast';
import {auth, store} from '../../../Firebase/firebase-config';
import {navigate} from "gatsby"
// create json export
const read = {};

read.permissionList = async()=>{
    try {
        const doc = await store.collection('roles').doc(localStorage.getItem('user')).get()
        .then(
            (doc)=>{
                console.log(doc.data())
                return doc.data()
            }
        )
        
    } catch (error) {
        
    }
};

export default read;
