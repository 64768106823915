import React, {useState, useEffect} from 'react';
import {navigate} from "gatsby"
import {store, auth} from '../Firebase/firebase-config';
// import list from './list';
// import fontawesome
import toastMsg from '../Toast';

const Access = (props) => {

//    const user = localStorage.getItem('user');

    const section = props.section;
    const roles = async ()=>{
        await store.collection('roles').doc(localStorage.getItem('user')).get()
        .then(res=>{
            localStorage.setItem('username', res.data().username);
            localStorage.setItem('roles', JSON.stringify(res.data()));
            if(!JSON.parse(localStorage.getItem('roles'))[section]){
                toastMsg.errorToast("No tienes permisos para esta sección");
                navigate('/login');
            }
            
        });
    };
    useEffect(() => {
        if(localStorage.getItem('user') == undefined){
            navigate('/login');
        }else{
            roles();
        }
    })
    
    return(null);
}

export default Access