// name: NavBar
// description: NavBar for maganement
// author: OsmaroBonilla
// date 11/10/2021
import React,{useEffect, useState} from 'react';
import {Navbar, Nav,Image} from 'react-bootstrap'
//Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome} from '@fortawesome/free-solid-svg-icons'

import toastMsg from '../../Toast';
import {store} from '../../Firebase/firebase-config';
import {navigate} from "gatsby"

// import dynamic element
import read from './actions/read';
import logout from './actions/logout';
const NavBusinessCard = (props) => {

  const [permissions, setPermissions] = useState([]);

  const [logouth, setLogouth] = useState('')

  function ButtonLogouth(){
    if(logouth == 'login'){
      return(
        <Nav.Link 
          className="size-icon"
        
        onClick={()=>{
          logout.logout()
        }}>Cerrar sesión</Nav.Link>


      )
    }else{
      return(null);
    }
  }
  const roles = async ()=>{
    await store.collection('roles').doc(localStorage.getItem('user')).get()
      .then(
        (doc)=>{
          if (!doc.exists) return;
          setPermissions(doc.data())
        }
      )
    
  }; 

  function List(){
    let listRole= [];
    Object.keys(permissions).map((item)=>{
      if(permissions[`${item}`] == true){
        listRole.push(`${item}`)
      }
    })
    console.log(listRole)
    return(
      <>
        {
          listRole.map(item =>(
            <Nav.Link href="/tarjeta-de-presentacion" className="size-icon"><FontAwesomeIcon icon={faHome} className="size-icon"/> {item}</Nav.Link>
          ))
        }
      </>
    )
  }

  useEffect(() => {
    if(localStorage.getItem('user') != undefined){
      setLogouth('login');
      roles();

      // butonLogouth();
    }
  }, [])
    
    return(
        <>
        <Navbar collapseOnSelect expand="lg" className="bg-navbar nb-text" variant="dark">
        <Navbar.Brand href="/"><Image src='https://firebasestorage.googleapis.com/v0/b/argos-tool.appspot.com/o/global%2Flogod-04.png?alt=media&token=d5110bcf-cec7-4c47-ab08-31f436cd4aff' className="ajust-logo" alt="logo-empresa" /></Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="mr-auto">
          </Nav>
          <Nav>
           <List />
           <ButtonLogouth />

          </Nav>
        </Navbar.Collapse>
      </Navbar>
        </>
    )
}

export default NavBusinessCard